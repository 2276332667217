import { camelCase } from '@s-libs/micro-dash';

import {
  Action,
  ActionTracker,
  PageEventParams,
  SegmentTracker,
  TrackEventParams,
  TrackerResponse,
} from '@varsitytutors/event-tracker';
import { NRMetadata, TrackEventArgsType, TrackEventNameType } from '../@types/schema';

import buildSegmentPageEvent from './build-page-event';
import transformParams from './transform-params';

interface ActionTrackerInterface {
  track(params: Action): Promise<Response>;
}

interface SegmentTrackerInterface {
  page: (params: PageEventParams) => Promise<TrackerResponse>;
  track(params: TrackEventParams): Promise<TrackerResponse>;
}

const defaultTracker: ActionTrackerInterface = new ActionTracker({
  applicationId: process.env.NEXT_PUBLIC_ACTIONTRACKER_APPID,
  endpoint: process.env.NEXT_PUBLIC_ACTIONTRACKER_ENDPOINT,
});

let segmentTrackerInstances: Record<string, SegmentTrackerInterface> = {};

const initInstance = (
  segmentWriteKey: string,
  params?: Record<string, unknown>
): SegmentTrackerInterface => {
  const currentInstance = segmentTrackerInstances[segmentWriteKey];
  if (currentInstance) return currentInstance;
  const newInstance = new SegmentTracker(segmentWriteKey, {
    ...params,
  });
  segmentTrackerInstances[segmentWriteKey] = newInstance;
  return newInstance;
};

const defaultSegmentTracker: SegmentTrackerInterface = initInstance(
  process.env.NEXT_PUBLIC_SEGMENT_LLT_ACTIONTRACKER_ID,
  { excludeEvents: ['apiFetch', 'scroll'] }
);

const getTrackingProperty = (el, propertyName) => {
  const matched = el.className.match(new RegExp(`js-${propertyName}:([^ ]+)`));
  return el.dataset[camelCase(propertyName)] || (matched && matched[1]);
};

/**
 * Helper function to raise a tracking event on the given DOM element.
 * @param el - The element to track
 * @param eventName - The name of the event
 * @param args - Any custom arguments to track
 */
export const raiseTrackingEvent = (el: Element, eventName: string, args?: any): CustomEvent => {
  const target = el.tagName;
  const className = el.className;
  const href = el.getAttribute('href');
  // TODO: const leadFormId = leadFormSelectors.getLeadFormId(this);
  // TODO: const panelId = leadFormSelectors.getLeadFormPanelId(this) || stepModel.getStepId(this);
  const text = (el.textContent || '').replace(/\s+/, ' ').trim();
  const category = getTrackingProperty(el, 'category') || 'nerdy-marketing';

  const eventArgs = {
    bubbles: true,
    detail: {
      category,
      className,
      eventName,
      href,
      target,
      text,
      ...(args || {}),
    },
  };

  const event = new CustomEvent('track', eventArgs);
  el.dispatchEvent(event);

  return event;
};

async function trackActionTracker(
  eventName: TrackEventNameType,
  transformed: { [x: string]: any },
  args: TrackEventArgsType,
  tracker: ActionTrackerInterface
) {
  try {
    await tracker.track(transformed);
  } catch (ex) {
    if (ex instanceof TypeError && process.env.NODE_ENV !== 'production') {
      return console.debug('vt-events:', eventName, args);
    }
    return Promise.reject(ex);
  }
}

async function trackSegmentTracker(
  eventName: TrackEventNameType,
  transformed: { [x: string]: any },
  tracker: SegmentTrackerInterface
) {
  return await tracker.track({
    eventName,
    properties: { ...transformed, applicationId: process.env.NEXT_PUBLIC_ACTIONTRACKER_APPID },
  });
}

export function defaultParams(eventName: string, args: Record<string, unknown>) {
  const params = {
    customEventData: {
      action: eventName,
    },
    ...args,
  };
  return params;
}

const newRelicTrack = (
  eventName: string,
  metadata: NRMetadata,
  transformed: { [x: string]: any }
) => {
  if (window['newrelic'])
    window['newrelic'].addPageAction(eventName, {
      ...transformed,
      customEventData: {
        ...transformed?.customEventData,
        ...metadata,
      },
    });
};
/**
 * Tracks an event using action tracker as default trackers
 */
export const track = async (
  eventName: TrackEventNameType,
  args: TrackEventArgsType,
  actionTracker: ActionTrackerInterface = defaultTracker,
  segmentTracker: SegmentTrackerInterface = defaultSegmentTracker
) => {
  const { newrelicMetadata, ...restOfArgs } = args;
  const transformed = transformParams(eventName, restOfArgs);

  newRelicTrack(eventName, newrelicMetadata, transformed);
  const promises = [
    trackActionTracker(eventName, transformed, args, actionTracker),
    trackSegmentTracker(eventName, transformed, segmentTracker),
  ];

  return Promise.all(promises);
};

export const page = async (
  args: TrackEventArgsType,
  segmentTracker: SegmentTrackerInterface = defaultSegmentTracker
) => {
  return segmentTracker.page(buildSegmentPageEvent(args));
};
