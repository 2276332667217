'use client';

import ButtonUnstyled from '@mui/core/ButtonUnstyled';
import { Spinner } from '@nrdy-marketing-engine/spinner';
import { ReactNode } from 'react';
import useButton from './use-button';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  nofollow?: boolean;
  onClick?: (event?: any) => void;
  target?: string;
  text?: string;
  trigger?: 'get-started';
  variant?: string;
}

function Wrapper(props: { children: ReactNode; href: string }) {
  const { href, children } = props;

  if (!href) {
    return <>{children}</>;
  }

  return <a href={href}>{children}</a>;
}

function Button({
  className,
  disabled = false,
  href,
  loading,
  onClick,
  target,
  text,
  trigger,
  variant,
  children = text,
  nofollow,
  ...rest
}: ButtonProps) {
  const { classes, clickTrackable, onButtonClick, transitionClasses } = useButton({
    className,
    href,
    loading,
    onClick,
    target,
    text,
    trigger,
    variant,
  });

  return (
    <Wrapper href={href}>
      <ButtonUnstyled
        className={classes}
        data-trigger={trigger}
        disabled={disabled}
        onClick={onButtonClick}
        ref={clickTrackable}
        rel={nofollow ? 'nofollow' : undefined}
        {...rest}
      >
        <span className={transitionClasses}>{children}</span>
        {loading && <Spinner className="absolute left-1/2 top-1/2 -ml-3 -mt-3" />}
      </ButtonUnstyled>
    </Wrapper>
  );
}

export default Button;
